<template>
<div class='page'>
 
 <industrialbanner :IndustrialbannerData="IndustrialbannerData"></industrialbanner>
  <myhead :hbg="hbg"></myhead>
  <bread :bbg="bbg"></bread> 
 <industrieshead :industriesheadLists="industriesheadLists"></industrieshead>
   <applications2 :ApplicationsLists="ApplicationsLists"></applications2>
    <capabilities :CapabilitiesList="CapabilitiesList"></capabilities>
  <customers></customers>
 
</div>
</template>
<script>
import bread from '../../components/bread.vue'
import industrialbanner from '../../components/Industrialbanner.vue'
import myhead from "../../components/myHead.vue";
import industrieshead from "../../components/industrieshead.vue";
import applications2 from "../../components/Applications2.vue";
import customers from "../../components/Customers.vue"
import capabilities from "../../components/Capabilities.vue"

export default {
    components: {
    myhead,
    industrieshead,
    applications2,
    customers,
    capabilities,
    bread,
    industrialbanner,
  },
  data() {
    return {
           CapabilitiesList:{
        H1:'PRODUCT DEVELOPMENT POST-PROCESSING CAPABILITIES',
        lists:[{ 
            title:'Anodizing',
        },{
            title:'Passivation',
        },{
            title:'Electroplating',
        },{
            title:'Powder Coating',
        },{
            title:'Insert Installation',
        },{
            title:'Heat Treatment',
        },{
            title:'Sandblasting',
        },{
            title:'Painting',
        }],
        img: require('../../assets/img/productDevelopmentcal1.png')
    },
      IndustrialbannerData:{
				H1:'Product Development',
				info:'Industrial design and engineering consultancies are some of the most innovate and creative enterprises on the planet. You are the backbone of our business too, HLH work with product designers the world',
				background:'#0B090A',
				img:require('../../assets/img/productDevelopmentbanner.png'), 
		
			},

    hbg:{
        bg:'#ffffff'

    },
    bbg:{
 bg:'#ffffff',
 color:'#000000'
    },
      industriesheadLists: {
        ref: "PRODUCT DEVELOPMENT",
        H1: "PRODUCT DEVELOPMENT",
        info: `The connection of prototypes and product development is inseparable, which means we are very closed to each other. You need to develop new products and we can support you on manufacturing. HLH worked with many superior product designer around the world to make their fantastic and creative design become real product.`,
        imgs: [
          {
            src: require("../../assets/img/productDevelopment1.png")
          }
        ],
        h2: "Top Product Development Applications",
        data: [
          {
            content:  "Office automation device"
          },
          {
            content: "Home appliances"
          },
          {
            content: "Life aesthetics functional products"
          },
          {
            content: "Enclosures"
          },
          {
            content:
              "Fit check gauges"
          }
        ]
      },
      ApplicationsLists: {
        H1: "WHY PRODUCT DEVELOPMENT COMPANIES CHOOSE HLH",
        data: [
          {
            img: require("../../assets/img/communicationsmdoel2.png"),
            title: "Interactive Design",
            info: "Based on accurate and personalized data collection, our technical team will put forward reasonable suggestions for customers' product design, and use our rich experience in the field of intelligent hardware to provide customers with optimized and achievable solutions."
          },
          {
            img: require("../../assets/img/industrialmodel22.png"),
            title: "Material Diversification",
            info: "Hundreds of materials to choose from give new life and energy to consumer products, bringing customer satisfaction."
          },
          {
            img: require("../../assets/img/productDevelopmentmodel1.png"),
            title: "Comprehensive Solution",
            info: "The huge design team, mature technical team and caring service team have the ability to provide customers with comprehensive solutions from needs to products."
          }
        ]
      }
    };
  },
  
  methods: {},
  mounted() {},
  created() {},
  props: {},
  watch: {},

};
</script>
<style scoped lang="scss">
.page {
}
.bannerBg {
  width: 100%;
  height: 620px;
  background: red;
}
</style>